import { NgModule } from '@angular/core';

// Components
import { PrivacyComponent } from './components/privacy/privacy.component';

// Modules
import { PrivacyRoutingModule } from './privacy-routing.module';

@NgModule({
  imports: [
    PrivacyRoutingModule
  ],
  exports: [],
  declarations: [PrivacyComponent],
  providers: [],
})
export class PrivacyModule {
}
