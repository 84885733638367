import { APP_INITIALIZER, NgModule } from '@angular/core';

// Services
import { AppInitializerService } from './services/app-initializer.service';
import { ConfigResolverService } from './services/config-resolver.service';
import { HttpService } from './services/http.service';

export function initialize(appInitializer: AppInitializerService) {
  return () => appInitializer.init();
}

@NgModule({
  imports: [],
  declarations: [],
  providers: [
    AppInitializerService,
    {
      provide: APP_INITIALIZER,
      useFactory: initialize,
      deps: [AppInitializerService],
      multi: true
    },
    ConfigResolverService,
    HttpService,
  ],
})
export class CoreModule {
}
