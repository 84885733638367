import { Injectable } from '@angular/core';

// Services
import { HttpService } from '../../modules/core';

// RxJs
import { Observable } from 'rxjs';

@Injectable()
export class FeedbackService {

  constructor(
    private readonly httpService: HttpService
  ) {
  }

  /**
   * Send feedback to server
   * @param form
   */
  sendFeedback(form: any): Observable<any> {
    return this.httpService.send(
      {
        url: 'mail/send',
        method: 'POST',
        body: form
      }
    )
  }
}
