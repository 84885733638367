import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { catchError } from 'rxjs/operators';

import { SKIP_AUTH_HEADER } from '../constants/http-headers';

@Injectable()
export class ConfigResolverService {
  private config: Object = null;

  constructor(private httpClient: HttpClient) {
  }

  get(key: string): any {
    return this.config[ key ];
  }

  load() {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get('config.json', {
          headers: new HttpHeaders({
            [ SKIP_AUTH_HEADER ]: 'true'
          })
        })
        .pipe(
          catchError((error: any): any => {
            console.log('Configuration file "config.json" could not be read');
            reject();
          })
        )
        .subscribe(configResponse => {
          this.config = configResponse;
          resolve();
        });
    });
  }
}
