import { Injectable } from '@angular/core';

// Services
import { ConfigResolverService } from './config-resolver.service';

@Injectable()
export class AppInitializerService {
  constructor(
    private configResolver: ConfigResolverService,
  ) {
  }

  init() {
    return new Promise((resolve, reject) => {
      this.configResolver
        .load()
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
