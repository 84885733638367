import { NgModule } from '@angular/core';

import * as services from './services';

import * as components from './components';

const sharedComponents = [
  components.MapComponent
]

// import { GoogleMapsModule } from '@angular/google-maps'

@NgModule({
  imports: [
    // GoogleMapsModule
  ],
  exports: [
    sharedComponents
  ],
  declarations: [
    sharedComponents
  ],
  providers: [
    services.FeedbackService
  ],
})
export class SharedModule {
}
