<header class="header">
  <div class="header__wrapper">
    <div class="header__top">
      <a routerLink="/" class="header__logo">
        <img src="/assets/images/logo-footer.png" alt="RichQuickMedia">
      </a>
    </div>
  </div>
</header>

<div class="container">
  <h1>PRIVACY</h1>
  <h2>Introduction</h2>
  <p>
    This Privacy Policy governs each website, mobile site, application/tool, and/or other service, regardless of how
    distributed, transmitted, published, or broadcast (each, a “Website”) provided by RichQuickMedia, its parent,
    subsidiaries and/or affiliates (“we,” “us,” or “our”) that links to this Privacy Policy, which is binding on all
    those who access, visit and/or use the Website, whether acting as an individual or on behalf of an entity, including
    you and all persons, entities, or digital engines of any kind that harvest, crawl, index, scrape, spider, or mine
    digital content by an automated or manual process or otherwise (collectively, “you” or “your”).
  </p>
  <h2>
    Privacy Policy
  </h2>
  <h3>
    1. The Type of Information the Website Collects:
  </h3>
  <p>
    A. The following Privacy Policy summarizes the various ways we treat personally identifiable and other information
    about you and any device from which you visit the Website. This Privacy Policy does not cover information collected
    through sites, applications, destinations, or services linked to from the Website.
  </p>
  <p>
    B. The Website generally collects personally identifiable information with your specific knowledge and consent. For
    instance, when you fill out a form, complete a survey, make a purchase, subscribe to any of our publications,
    register for certain services, or register and/or set up an account to access certain portions of the Website, or
    the Website as a whole, you may provide a credit, debit, or charge card number, or other payment information such as
    your name, telephone number, email, street address, age, gender, mobile phone number, and other personally
    identifiable information. You may also provide information about your company such as your position/title, customer
    demographics, budget preferences, size of company, and other information regarding your job and company so we can
    better cater to your needs. All such information shall be referred to in this Privacy Policy as your “Registration
    Information”.
  </p>
  <p>
    C. Our servers may also automatically collect information about you, your online behavior and the device from which
    you access the Website. The information collected may include the make, model, settings, specifications, and
    geographic location of your device, as well as clickstream data related to your online behavior. This information is
    primarily used to provide insight into our audience’s preferences and how we can best cater to these preferences, as
    described in the next section.
  </p>
  <h3>
    2. How the Website Uses and Shares Information About You:
  </h3>
  <p>
    A. We use personally identifiable information you supply through the Website to provide you with the product,
    service, and/or content you have requested. For example, if you subscribe to any of our online content, we may use
    your e-mail address to send you a confirmation notice as well as the content you have requested on an ongoing basis
    as long as you are subscribed. Similarly, if you enter an online sweepstakes, we will use this information to notify
    you if you are a winner. We may also use the information to communicate with you about new features, products or
    services, and/or to improve the services that we offer by tailoring them to your needs.
  </p>
  <p>
    B. Unless otherwise specified on the Website, we may share any information collected about you and your device with
    our parent, subsidiaries, affiliates and with carefully selected companies who we think may offer services and/or
    products that may be of interest to you. If you do not wish to have your personally identifiable information shared
    with third parties, contact our Privacy Policy Coordinator listed at the end of this document. If you do not wish to
    receive future commercial messages from us, you may unsubscribe by clicking the link at the bottom of any of our
    email messages. Please note, however, that your information may still be used to communicate transactional or
    relationship-related messages to you.
  </p>
  <p>
    C. We may also allow access to our database by third parties that provide us with services, such as graphic design
    and copywriting services, but only for the purpose of and to the extent necessary to provide those services. And if
    you choose to purchase products and/or services, including without limitation proprietary online marketing tools
    and/or content, on or through features on the Website, we may forward your information to third parties for services
    such as credit card or other payment processing, order fulfillment, credit pre-authorization, and address
    verification. Some of the information you have provided about yourself may be managed by third parties. In such
    cases, the information may be used by such third parties, each pursuant to its own policies. While we will only
    authorize them to use this information for the express purpose for which it is provided, we do not bear any
    responsibility for inappropriate use by these third parties.
  </p>
  <p>
    D. We reserve the right to access, use, and share with others your personally identifiable information for purposes
    of health, safety and other matters in the public interest. We may also provide access to our database in order to
    cooperate with official investigations or legal proceedings initiated by governmental and/or law enforcement
    officials, as well as private parties as far we are required.
  </p>
  <p>
    E. We reserve the right to use the information we collect about your computer, mobile or other device (including its
    geographic location which may be used to cater geographic-specific sponsored messaging to users), which may at times
    be able to identify you, for any lawful business purpose, including to help diagnose problems with our servers, to
    gather broad demographic information, analyze trends, track users’ movements around the Website, and to otherwise
    administer the Website. We also reserve the right to use, transfer, sell, and share aggregated, anonymous data about
    our users as a group for any lawful business purpose, such as analyzing usage trends and seeking compatible
    advertisers, sponsors, clients and customers.
  </p>
  <p>
    F. In the event all or a portion of the assets owned or controlled by us, our parent or any subsidiary or affiliated
    entity are sold, assigned, transferred or acquired by another company, the information about our Website users may
    be among the transferred assets.
  </p>
  <p>
    G. We reserve the right to identify you from your Registration Information and/or to merge or co-mingle anonymous or
    non-personally identifiable data about you, your offline and online behavior, and/or your computer, mobile or other
    device (including its geographic location), with your Registration Information and/or other personally identifiable
    data for any lawful business purpose.
  </p>
  <h3>
    3. Local Device Storage and other Tracking Technologies:
  </h3>
  <p>
    A. The Website may place and/or store code or other types of information, known as “Local Device Storage” (e.g.,
    “cookies”) on your computer, mobile or other device. We may use Local Device Storage for any lawful business
    purpose, primarily as part of marketing and business development activities, and also to record registration and
    personalization information. For example, if you register on any part of the Website you may be given the option to
    save your user name and password via Local Device Storage. Any information stored through Local Device Storage may
    be shared with our parent, subsidiaries, and affiliates.
  </p>
  <p>
    B. If you do not want to utilize Local Device Storage, your computer, mobile or other device may include an option
    to disable it. However, if you disable Local Device Storage, some portions of the Website may not function properly.
  </p>
  <h3>
    4. Transfer of Information:
  </h3>
  <p>
    Please be advised that your information may be transferred to, and maintained on, servers and databases located
    outside of your state, province, country or other governmental jurisdiction where the privacy laws may not be as
    protective as your jurisdiction. Your consent to this Privacy Policy followed by your submission of such information
    represents your agreement to any such transfer.
  </p>
  <h3>
    5. Information Security and Notification:
  </h3>
  <p>
    A. Because no data transmission is completely secure, we cannot guarantee the security of the information you send
    to us or the security of our servers, networks or databases, and by using the Website you agree to assume all risk
    connected to the information collected by us when you access, visit and/or use the Website, including the personally
    identifiable information or other Registration Information you have provided through the Website, and that we are
    not responsible for any loss of such information or the consequences thereof.
  </p>
  <p>
    B. Moreover, if you elect to store personal information where others may access it, we are not responsible for any
    loss of such information or the consequences thereof. If a computer, mobile or other device, that contains your
    personally identifiable information or other Registration Information is lost or stolen, it is your responsibility
    to take all the steps necessary to protect yourself.
  </p>
  <p>
    C. In the unlikely event that we believe that the security of your information in our possession or control may have
    been compromised, we may seek to notify you. If notification is appropriate, we may notify you via your computer,
    mobile or other device.
  </p>
  <h3>
    6. Children and Parents:
  </h3>
  <p>
    A. This Website is not intended for use by children, especially those under age 13. While we advise against minors
    disclosing personal information about themselves via the Website, there is no plausible way for us to enforce an age
    restriction on users. However, if it comes to our attention that an underage user is supplying personal information
    through the Website, we will take actions to erase this information and discontinue communications with the underage
    individual.
  </p>
  <p>
    B. In order to protect your children from unsolicited messages from outside parties, please advise them not to
    disclose personal information in publicly accessible areas of the Website.
  </p>
  <h3>
    7. Privacy Policy Coordinator, Opt-Out Requests and Other Privacy Questions:
  </h3>
  <p>
    If you would like to opt-out of having your personally identifiable information shared with third parties and/or
    request updates or changes to your information or your preferences regarding receiving future communications from us
    (both of which are discussed in Section 2 (B)), or if you have any questions about any aspect of this policy, please
    contact our Privacy Policy Coordinator.
  </p>
  <h4>
    Privacy Policy Coordinator <br>
    RichQuickMedia s.r.o. <br>
    Address: RichQuickMedia s.r.o. Na okraji 381/41, Veleslavín, 162 00 Praha 6<br>
    Email: <span>support@richquickmedia.com</span>
  </h4>
</div>
