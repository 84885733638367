import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/en',
    pathMatch: 'full'
  },
  {
    path: 'en',
    loadChildren: () => import('./modules/main/main.module').then(mod => mod.MainModule)
  },
  // {
  //   path: 'ru',
  //   loadChildren: () => import('./modules/ru/main-ru.module').then(mod => mod.RuModule)
  // },
  {
    path: 'cze',
    loadChildren: () => import('./modules/cze/main-cze.module').then(mod => mod.MainCzeModule)
  },
  {
    path: 'privacy',
    loadChildren: () => import('./modules/privacy/privacy.module').then(mod => mod.PrivacyModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
