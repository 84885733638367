import { NgModule } from '@angular/core';

// Routing
import { RouterModule, Routes } from '@angular/router';

// Components
import { PrivacyComponent } from './components/privacy/privacy.component';

// Components

const routes: Routes = [
  {
    path: '',
    component: PrivacyComponent,
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PrivacyRoutingModule {
}
